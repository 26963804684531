.MuiPaginationItem-previousNext[aria-label="Go to next page"]::before {
  content: "Next";
  padding-right: 10px;
}
.MuiPaginationItem-previousNext[aria-label="Go to previous page"]::after {
  content: "Prev";
  padding-left: 10px;
}
.MuiPaginationItem-firstLast[aria-label="Go to first page"]::after {
  content: "First";
  padding-left: 10px;
}
.MuiPaginationItem-firstLast[aria-label="Go to last page"]::before {
  content: "Last";
  padding-right: 10px;
}
.MuiPaginationItem-root {
  font-size: 16px;
}
.MuiPaginationItem-root:hover {
  border-radius: 4px;
}
.MuiPaginationItem-root[aria-current="true"] {
  background-color: #bb4430 !important;
  color: #ffffff;
  border-radius: 4px;
}
