body {
  background-color: #f3f8fc;
}
.DraftEditor-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: rgba(0, 0, 0, 0.87);
  line-height: 20px;
}
.terms-conditions p {
  margin-bottom: 16px;
  line-height: 20px;
}

.terms-conditions p strong {
  font-weight: 700;
}

@font-face {
  font-family: "PoppinsSemiBold";
  src:
    local("PoppinsSemiBold"),
    url("./fonts/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsMedium";
  src:
    local("PoppinsMedium"),
    url("./fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  src:
    local("Poppins"),
    url("./fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoMedium";
  src:
    local("RobotoMedium"),
    url("./fonts/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  src:
    local("Roboto"),
    url("./fonts/Roboto-Regular.ttf") format("truetype");
}
